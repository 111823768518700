import { render, staticRenderFns } from "./MotorNotice.vue?vue&type=template&id=47d70e97&scoped=true&"
import script from "./MotorNotice.vue?vue&type=script&lang=js&"
export * from "./MotorNotice.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47d70e97",
  null
  
)

export default component.exports