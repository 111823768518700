<template>
    <div class="pt-3">
        <h4>defaultní upozornění</h4>



        <div class="d-flex w-50 py-2">

            <div class="col">
                <label>nova  řada</label>
                <input class='form-control' type="text" v-model="new_name"/>
            </div>
            <div class="col">
                <button type="button" @click="createL1()" class="btn btn-primary mt-4">Pridat</button>
            </div>
        </div>

        <table class="table table-striped">
            <tr>
                <th>ID</th>
                <th>jednoznačný název řady</th>
                <th colspan="2">
                    &nbsp;
                </th>
            </tr>
            <tr v-for="t in items" :key="t.id">
                <td># {{t.id}}</td>
                <td>{{t.productline}}</td>
                <td>
                    <a href="#" @click.prevent="edit(t)">upravit</a>
                </td>
                <td><a href="#" @click.prevent="destroy(t)">smazat</a></td>
            </tr>
        </table>



        <modal name="hello-world" :adaptive="true" :draggable="true" height="auto">
            <div class="p-2">
                <div class="form-group">
                    <label>Nazev</label>
                    <input type="text" class="form-control" v-model="item.productline">
                </div>

                <div class="form-group">
                    <label>Popis</label>

                </div>
                <div class="d-flex">
                    <div class="ml-auto">
                        <button class="btn btn-primary" @click="updateItem()">Ulozit</button>
                    </div>
                </div>


            </div>

        </modal>

    </div>
</template>

<script>

    const axios = require("axios");
    axios.defaults.withCredentials = true;
    export default {
        name: "MotorNotice",
        data(){
            return{

                editorConfig: {

                },
                new_name:'',
                new_atype_id:null,
                new_text:'',
                new_st_name:'',
                items:[],
                item:[]
            }
        },
        methods:{
            edit(t){
                this.item = t
                this.$modal.show("hello-world");
            },
            updateItem(){
                const data={
                    productline: this.item.productline
                }
                axios.put(process.env.VUE_APP_API_ROOT_URL + "/motor_notices/"+this.item.id, data).then((result) => {
                    if (result.status == 200) {
                        console.log("ok", result.data)
                        this.$modal.hide("hello-world");
                    }
                })
            },
            createL1(){
                axios.post(process.env.VUE_APP_API_ROOT_URL + "/motor_notices", {
                    productline: this.new_name
                }).then((result) => {
                    if (result.status ==201){
                        console.log("ok", result.data)
                        if (result.data.id>0){
                            this.items.push(result.data)
                        }
                    }
                }).catch((err) => {
                    console.error(err)
                });
                this.new_name=''
            },
            destroy(t){
                if (confirm("Opravdu smazat?")) {
                    axios.delete(process.env.VUE_APP_API_ROOT_URL + "/motor_notices/" + t.id).then((result) => {
                        if (result.status == 204) {
                            console.log("ok")
                            for (var i = 0; i < this.items.length; i++) {
                                if (this.items[i].id == t.id) {
                                    this.items.splice(i, 1)
                                }
                            }

                        }
                    }).catch((err) => {
                        console.error(err)
                    });
                }
            },
            _reload(){
                axios.get(process.env.VUE_APP_API_ROOT_URL + "/motor_notices").then((result) => {
                    if (result.status ==200){
                        this.items = result.data
                    }
                }).catch((err) => {
                    console.error(err)
                });
            }
        },
        mounted() {
            this._reload()
        }

    }
</script>

<style scoped>

</style>